exports.components = {
  "component---src-genres-genre-page-js": () => import("./../../../src/genres/GenrePage.js" /* webpackChunkName: "component---src-genres-genre-page-js" */),
  "component---src-genres-genres-page-js": () => import("./../../../src/genres/GenresPage.js" /* webpackChunkName: "component---src-genres-genres-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-[permalink]-quotation-js": () => import("./../../../src/pages/[permalink]/quotation.js" /* webpackChunkName: "component---src-pages-[permalink]-quotation-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-publications-publication-page-js": () => import("./../../../src/publications/PublicationPage.js" /* webpackChunkName: "component---src-publications-publication-page-js" */),
  "component---src-publications-publications-page-js": () => import("./../../../src/publications/PublicationsPage.js" /* webpackChunkName: "component---src-publications-publications-page-js" */),
  "component---src-subjects-subject-page-js": () => import("./../../../src/subjects/SubjectPage.js" /* webpackChunkName: "component---src-subjects-subject-page-js" */),
  "component---src-subjects-subjects-page-js": () => import("./../../../src/subjects/SubjectsPage.js" /* webpackChunkName: "component---src-subjects-subjects-page-js" */),
  "component---src-templates-confidential-work-js": () => import("./../../../src/templates/confidentialWork.js" /* webpackChunkName: "component---src-templates-confidential-work-js" */),
  "component---src-templates-work-js": () => import("./../../../src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */)
}

