import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from "@sentry/tracing";

try {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
    integrations: [new BrowserTracing({
      tracingOrigins: [/.*/],
    })],
  });
} catch (e) {
  console.error("Couldn't initialize Sentry: ", e)
}